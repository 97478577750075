import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'
import Footer from '../Footer/Footer'
import RouteLoader from '../common/RouteLoader'

const LoginLayout = () => (
    <>
        <header />
        <main>
            <Suspense fallback={<RouteLoader />}>
                <Outlet />
            </Suspense>
        </main>
        <Footer />
    </>
)

export default LoginLayout
