import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import LoginLayout from './Layout/LoginLayout'
import BasicLayout from './Layout/BasicLayout'

const Home = lazy(() => import('./Views/Home/Home'))
const Login = lazy(() => import('./Views/Login/Login'))

const App = () => (
    <Routes>
        <Route path="/login" element={<LoginLayout />}>
            <Route
                index
                element={<Login />}
            />
        </Route>
        <Route path="/" element={<BasicLayout />}>
            <Route
                index
                element={<Home />}
            />
            <Route
                path=":keyword"
                element={<Home />}
            />
        </Route>
    </Routes>
)

export default App
