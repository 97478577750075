import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getLinks = createAsyncThunk('data/getLinks', async (token, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_API_URL, {
        headers: { Authorization: `Bearer ${token}` }
    })
    const data = await response.json()
    if (response.status !== 200) return rejectWithValue(data.errors)
    if (data.ok === 0) return rejectWithValue(data.error.detail)
    return {
        links: data.data,
        sectors: data.sectors
    }
})

const initialState = {
    links: [],
    sectors: [],
    status: 'idle'
}

const dataSlice = createSlice({
    name: 'data',
    initialState,
    extraReducers: {
        [getLinks.pending]: (state) => {
            state.status = 'loading'
        },
        [getLinks.fulfilled]: (state, { payload }) => ({
            ...state,
            status: 'idle',
            links: state.links.concat(payload.links),
            sectors: state.sectors.concat(payload.sectors)
        }),
        [getLinks.rejected]: (state) => {
            state.status = 'rejected'
        }
    },
    reducers: {
        resetLinks: () => initialState
    }
})

export const { resetLinks } = dataSlice.actions
export default dataSlice.reducer
