import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetUser } from '../redux/user'
import { resetLinks } from '../redux/data'
import logoImage from '../../assets/img/aokitext-1858c6e9ebd4d01e5312ca4383430077.png'

const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const status = useSelector((state) => state.data.status)
    return (
        <header className="fixed top-0 left-0 w-full h-[var(--header-size)] z-40 bg-aoki-gray-light shadow-md">
            <div className="page-container h-full">
                <div className="flex items-center justify-between h-full gap-12">
                    <a
                        href="https://aokitech.com.ar/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img alt="AOKI Logo" src={logoImage} className="w-16" />
                    </a>
                    {
                        status === 'loading' && (
                            <div
                                className="w-8 h-8 rounded-full border-2 border-b-aoki-orange animate-spin"
                            />
                        )
                    }
                    <button
                        type="button"
                        className="btn transparent"
                        onClick={() => {
                            dispatch(resetUser())
                            dispatch(resetLinks())
                            navigate('/login')
                        }}
                    >
                        Cerrar Sesión
                    </button>
                </div>
            </div>
        </header>
    )
}

export default Header
